import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchListeningFree(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/listenings/free', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchListeningPremium(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/listenings/premium', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
